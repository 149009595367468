<template>
  <header>
    <button id="menu-btn" class="menu-btn" @click="toggleMenu()" v-bind:class="{'open': isMenuOpen}">
      <span class="close" v-if="!isMenuOpen"><img src="../assets/images/menu.svg" /><span>Menu</span></span>
      <span class="open" v-if="isMenuOpen"><img src="../assets/images/close.svg" /><span>Close</span></span>
    </button>
    <div id="menu-wrap-overlay"  v-if="isMenuOpen" class="menu-wrap-overlay" @click="toggleMenu()"></div>
    <div id="menu-wrap" class="menu-wrap" v-bind:class="{'open': isMenuOpen}">
      <router-link to="/">Home</router-link>
      <router-link to="/digital-chaos">The digital chaos</router-link>
      <router-link to="/your-sacred-space">Your sacred space</router-link>
      <router-link to="/revolutionary-tool">A Revolutionary tool</router-link>
      <div class="has-submenu">
        <router-link to="/maps-gallery">Maps Gallery</router-link>
      </div>
      <div class="has-submenu">
        <router-link to="/epicentric-and-music">Epicentric & Music <span class="sub-menu-icon">></span></router-link>
        <div class="sub-menu">
          <router-link to="/epicentric-and-music/why-to-own-music">Why own music</router-link>
          <router-link to="/epicentric-and-music/music-lovers">Epicentric® Music Lovers</router-link>
        </div>
      </div>
      <router-link to="/epicentric-and-audio">Epicentric & Audio</router-link>
      <router-link to="/epicentric-and-visual">Epicentric & Visual</router-link>
      <router-link to="/epicentric-and-you">Epicentric & You</router-link>
      <router-link to="/epicentric-lovers">Epicentric Lovers</router-link>
      <router-link to="/epicentric-vision">The Epicentric vision</router-link>
      <router-link to="/epicentric-vs">Epicentric Vs</router-link>
      <a href="https://wiki.epicentric.world/" target="_blank">Epicentric Wiki</a>

      <router-link to="/about">About Us</router-link>
      <router-link to="/contact">Contact Us</router-link>
    </div>
    <router-link id="epicentric-logo" class="epicentric-logo" to="/"> 
      <img class="logo-desk" alt="Epicentric logo" src="../assets/logo/logo_desk.svg">
      <img class="logo-mobile" alt="Epicentric logo" src="../assets/logo/logo_mobile.svg">
    </router-link>
    <div class="cta-wrap">
      <a class="cta-btn signup" href="https://play01.epicentric.world/signup">
        Sign up
      </a>
      <a class="cta-btn login" href="https://play01.epicentric.world/">
        Log in
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderNav',
  data () {
    return {
      isMenuOpen: false
    }
  },
  watch: {
    $route () {
      window.scrollTo(0,0);
      this.isMenuOpen = false
    }
  },
  methods: {
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999999999;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $BG_W;
  border-bottom: 1px solid $GREY;
  .menu-btn {
    margin-right: 15px;
    padding: 5px 0px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    img {
      width: 35px;
      border: 1px solid $ORANGE;
      padding: 5px 7px;
      margin-right: 10px;
    }
    span {
      font-size: .8rem;
      opacity: 1;
      color: $ORANGE;
    }
    .open {
      display: flex;
      justify-content: center;
      img {
        padding: 7px 7px;
        border: 1px solid #333333;
      }
      span{
        align-self: center;
      }
    }
    .close {
      justify-content: center;
      display: flex;
      span{
        align-self: center;
      }
    }
    &.open{
      &:hover {
        .open {
          text-decoration: underline;
          opacity: 1;
          color: #333333;
        }
        .close {
          text-decoration: underline;
          opacity: 1;
          color: $ORANGE;
        }
      }
    }
  }
  .menu-wrap-overlay {
    position: fixed;
    top: 60px;
    left: 0px;
    z-index: 14;
    height: calc(100% - 60px);
    width: 100%;
    display: none;
    .open {
      display: block;
    }
  }
  .menu-wrap {
    position: fixed;
    top: 60px;
    left: 0px;
    border: 1px solid $ORANGE;
    border-bottom: 0px;
    background-color: #EDEDED;
    display: none;
    transition: all .3s ease-in;
    z-index: 15;
    &.open {
      display: block;
    }
    a {
      display: block;
      width: 100%;
      height: 35px;
      line-height: 35px;
      color: #333333;
      padding: 0 25px;
      padding-left: 15px;
      font-size: .8rem;
      border-bottom: 1px solid $ORANGE;
      transition: all .3s ease-in;
      text-decoration: none;
      &:hover {
        color: $ORANGE;
      }
      &.active {
        background-color: $ORANGE;
        color: #FAFAFA;
        &:hover {
          color: #FAFAFA;
        }
      }
      &.download{
        display: none;
      }
    }
    .has-submenu{
      position: relative;
      &:hover{
        background-color: darken(#EDEDED, 5%);
        .sub-menu{
            display: block;
        }   
      }
      .sub-menu{
        position: absolute;
        top: 0px;
        left: calc(100% + 1px);
        display: none;
        border: 1px solid #EA8B21;
        border-bottom: 0px;
        border-left: 0px;
        background-color: #EDEDED;
        width: 180px;
      }       
    }
  }
  .epicentric-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    opacity: 1;
    transition: all .4s ease-out;
    &.hide {
      opacity: 0;
    }
    img {
      height: 100%;
      &.logo-desk {
        display: block;
      }
      &.logo-mobile {
        display: none;
      }
    }
  }
  .cta-wrap{
    .cta-btn {
      display: inline-block;
      width: 70px;
      padding: 5px 0px;
      text-align: center;
      margin-left: 15px;
      text-decoration: none;
      font-size: .9rem;
      transition: all .3s ease-out;
      &.signup {
        border: 1px solid $GREEN;
        background-color: $GREEN;
        color: $TEXT_W;
        &:hover {
          color: $GREEN;
          background-color: $BG_W;
        }
      }
      &.login {
        border: 1px solid $ORANGE;
        background-color: $ORANGE;
        color: $TEXT_W;
        &:hover {
          color: $ORANGE;
          background-color: $BG_W;
        }
      }
      &.download{
        width: 90px;
        border: 1px solid $DOWNLOAD;
        background-color: $DOWNLOAD;
        color: $TEXT_W;
        &:hover {
          color: $DOWNLOAD;
          background-color: $BG_W;
        }
      }
    } 
  }
}
 
@media (max-width: 1023px) {
  header {
    .cta-wrap{
      .cta-btn{
        &.signup{
          padding-left: 0px;
          background-color: $GREEN;
          .cta-signup {
            display: none;
          } 
        }
      } 
    }
  }
}

@media (max-width: 1024px) {
  header {
    .menu-wrap{
      a {
        height: 45px;
        line-height: 45px;
      }
      .has-submenu{
        .sub-menu{
          margin-left: 5px;
          display: block;
          border-left: 1px solid #EA8B21;
        }   
      }
    }
  }
}

@media (max-width: 760px) {
  header {
    .menu-wrap {
      a {
        &.download{
          display: inline-block;
        }
      }
    }
    .cta-wrap{
      .download{
        display: none;
      }
    }
  }
}

@media (max-width: 550px) {
  header {
    .cta-wrap{
      .cta-btn{
        &.login{
          display: none;
        }
      } 
    }
  }
}

@media (max-width: 374px) {
  header {
    .epicentric-logo{
      img{
        &.logo-desk {
          display: none;
        }
        &.logo-mobile {
          display: block;
        }
      }
    }
  }
}
</style>
