<template>
  <HeaderNav/>
  <router-view/>
  <FooterBar/>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue'
  import FooterBar from '@/components/FooterBar.vue'
  export default {
    name: 'Epicentric-app',
    components: {
      HeaderNav,
      FooterBar
    },
    created () {
      const currentVersion = localStorage.getItem('version')
      if (currentVersion === null) {
        localStorage.setItem('version', window.wikiVersion)
        location.reload(true)
      } else {
        if (window.wikiVersion > currentVersion) {
          localStorage.setItem('version', window.wikiVersion)
          location.reload(true)
        }
      }
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/_reset.scss";
@font-face {
  font-family: 'titilliumWeb-Bold';
  src: url('./assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'titilliumWeb-Light';
  src: url('./assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'titilliumWeb-Regular';
  src: url('./assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body, html{
  width: 100%;
  background-color: $BG_B;
  background-image: url('./assets/images/background2022.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#app {
  width: 100%;
  font-family: 'titilliumWeb-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $TEXT_B;
  h1, h2, h3, h4, h5{
    &.bold{
      font-family: 'titilliumWeb-Bold';
    }
    .bold{
      font-family: 'titilliumWeb-Bold';
    }
    &.white{
      color: $TEXT_W;
    }
  }
  p{
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.4rem;
    &.bold{
      font-family: 'titilliumWeb-Bold';
    }
    .bold{
      font-family: 'titilliumWeb-Bold';
    }
    &.white{
      color: $TEXT_W;
    }
    &.bigger{
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  i{
    font-style: italic!important;
  }
  a{
    &.link{
      color: $GREEN;
    }
    &.link-btn{
      display: inline-block;
      position: relative;
      .off{
        width: 100%;
        opacity: 1;
      }
      .over{
        position: absolute;
        top: 0px;
        left: 9px;
        width: 100%;
        z-index: 9;
        opacity: 0;
        transition: all .4s ease-out;
      }
      &:hover{
        .over{
          opacity: 1;
        }
      }
    }
  }
  ol, ul{
    font-size: 1.1rem;
    line-height: 1.4rem;
    .bold{
      font-family: 'titilliumWeb-Bold';
    }
    li{
      text-align: left;
      margin-bottom: 7px;
    }
  }
  @media screen and (max-width: 768px) {
    ol, ul{
      padding-left: 20px;
    }
  }
  hr{
    border-color: $ORANGE;
  }
  img{
    max-width: 850px;
    &.full-w{
      width: 100%;
    }
    &.is-desktop{
      display: initial;
    }
    &.is-mobile{
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    img{
      &.full-w{
        width: 99%;
      }
      &.is-desktop{
        display: none;
      }
      &.is-mobile{
        display: initial;
      }
    }
  }

  .txt-orange{
    color: $ORANGE;
  }
  .txt-c{
    text-align: center;
  }
  .txt-l{
    text-align: left;
  }
  .text-orange{
    color: $ORANGE;
  }
  .page{
    padding-top: 60px;
    width: 100%;
    section{
      position: relative;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding-bottom: 120px;
      padding-top: 60px;
      border-bottom: 1px solid $ORANGE;
      &.txt-c{
        p{
          text-align: center;
        }
        .content-wrap{
          p{
            text-align: center;
          } 
        }
      }
      &.no-border{
        border: 0px;
      }
      &.white-bg{
        background-color: $BG_W;
      }
      &.white-gradient{
        &:after{
          content: "";
          display: block;
          width: 100%;
          height: 120px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          z-index: 1;
          background: linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(217,217,217,1) 40%, rgba(255,255,255,1) 100%);
        }
        background-color: $BG_W;
        border-bottom: 0px;
      }
      &.beta-section{
        padding-bottom: 60px;
      }
      .content-wrap{
        text-align: center;
        width: 100%;
        max-width: 740px;
        margin: 0 auto;
        &.sign-up-box{
          background: rgb(217,217,217);
          background: linear-gradient(90deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 50%, rgba(217,217,217,1) 100%);
          padding: 21px;
          border: 1px solid $GREEN;
          p{
            margin-bottom: 20px;
          }
          a{
            display: block;
            background-color: $GREEN;
            color: $TEXT_W;
            border: 1px solid $GREEN;
            padding: 6px 16px;
            text-decoration: none;
            width: 100px;
            margin: 0 auto;
            transition: all .3s ease-out;
            &:hover{
              background-color: $BG_W;
              color: $GREEN;
            }
          }
        }
      }
      .mb-short{
        margin-bottom: 15px;
      }
      .mb-half{
        margin-bottom: 30px;
      }
      .mb-regular{
        margin-bottom: 60px;
      }
      .mb-double{
        margin-bottom: 120px;
      }
      .mt-regular{
        margin-top: 60px;
      }
    }

    .sub-title{
      width: 100%;
      position: relative;
      margin-bottom: 5px;
      padding-bottom: 30px;
      text-transform: uppercase;
      color: $TEXT_B;
      font-size: 1.5rem;
      font-family: 'titilliumWeb-Light';
      &.text-orange{
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: initial;
        color: $ORANGE;
        &:before {
          top: initial;
          bottom: -10px;
          background: $ORANGE;
          background: -webkit-linear-gradient(to left $ORANGE, $ORANGE);
          background: linear-gradient(to left, $ORANGE, $ORANGE);
        }
        &:after {
          top: initial;
          bottom: -14px;
          background: $ORANGE;
          background: -webkit-linear-gradient(to left $ORANGE, $ORANGE);
          background: linear-gradient(to left, $ORANGE, $ORANGE);
        }
      }
      &:before {
        content: "";
        display: block;
        height: 1px;
        width: 25%;
        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translate(-50%, 0);
        background: $GREY;
        background: -webkit-linear-gradient(to left $GREY, $GREY);
        background: linear-gradient(to left, $GREY, $GREY);
      }
      &:after {
        content: "";
        display: block;
        height: 4px;
        width: 12.5%;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%, 0);
        background: $GREY;
        background: -webkit-linear-gradient(to left $GREY, $GREY);
        background: linear-gradient(to left, $GREY, $GREY);
      }
    }

    .title-clean {
      font-family: 'titilliumWeb-Light';
      font-size: 2rem;
      color: $TEXT_W;
      text-transform: uppercase;
      &.orange{
        color: $ORANGE;
      }
    }

    .title-box {
      position: relative;
      width: 100%;
      // height: 85px;
      padding-bottom: 30px;
      margin-bottom: 40px;
      &.sub-title{
        h1, h2, h3, h4 {
          font-family: 'titilliumWeb-Regular';
          color: $TEXT_B;
          font-size: 1.8rem;
        }
        &:before {
          background: $TEXT_B;
          background: -webkit-linear-gradient(to left darken($TEXT_B, 20%), $TEXT_B);
          background: linear-gradient(to left, darken($TEXT_B, 20%), $TEXT_B);
        }
        &:after {
          background: $TEXT_B;
          background: -webkit-linear-gradient(to left darken($TEXT_B, 20%), $TEXT_B);
          background: linear-gradient(to left, darken($TEXT_B, 20%), $TEXT_B);
        }
        .title-triangle {
          border-color: $TEXT_B transparent transparent transparent;
        }
        &.white{
          h1, h2, h3, h4 {
            color: $TEXT_W;
            text-transform: none;
          }
          &:before {
            background: $TEXT_W;
            background: -webkit-linear-gradient(to left darken($TEXT_W, 20%), $TEXT_W);
            background: linear-gradient(to left, darken($TEXT_W, 20%), $TEXT_W);
          }
          &:after {
            background: $TEXT_W;
            background: -webkit-linear-gradient(to left darken($TEXT_W, 20%), $TEXT_W);
            background: linear-gradient(to left, darken($TEXT_W, 20%), $TEXT_W);
          }
          .title-triangle {
            border-color: $TEXT_W transparent transparent transparent;
          }
        }
      }
      &.black{
        h1, h2, h3, h4 {
          color: $TEXT_B;
        }
        &:before {
          background: $TEXT_B;
          background: -webkit-linear-gradient(to left darken($TEXT_B, 20%), $TEXT_B);
          background: linear-gradient(to left, darken($TEXT_B, 20%), $TEXT_B);
        }
        &:after {
          background: $TEXT_B;
          background: -webkit-linear-gradient(to left darken($TEXT_B, 20%), $TEXT_B);
          background: linear-gradient(to left, darken($TEXT_B, 20%), $TEXT_B);
        }
        .title-triangle {
          border-color: $TEXT_B transparent transparent transparent;
        }
      }
      h1, h2, h3, h4 {
        font-family: 'titilliumWeb-Light';
        z-index: 5;
        font-size: 2rem;
        color: $TEXT_W;
        text-transform: uppercase;
        &.orange{
          color: $ORANGE;
        }
      }
      &:before {
        content: "";
        display: block;
        height: 1px;
        width: 25%;
        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translate(-50%, 0);
        background: $ORANGE;
        background: -webkit-linear-gradient(to left darken($ORANGE, 20%), $ORANGE);
        background: linear-gradient(to left, darken($ORANGE, 20%), $ORANGE);
      }
      &:after {
        content: "";
        display: block;
        height: 4px;
        width: 12.5%;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%, 0);
        background: $ORANGE;
        background: -webkit-linear-gradient(to left darken($ORANGE, 20%), $ORANGE);
        background: linear-gradient(to left, darken($ORANGE, 20%), $ORANGE);
      }
      .title-triangle {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 21px 12px 0 12px;
        border-color: $ORANGE transparent transparent transparent;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #app {
    // padding: 0 14px;
    .page{
      section{
        padding-bottom: 60px;
        padding-left: 14px;
        padding-right: 14px;
        &.white-gradient{
          &:after{
            height: 60px;
          }
        }
        .mb-regular{
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
