<template>
  <footer>
    <p>
      ©Copyright 2016-2023 <a class="link" href="https://www.virtuouscircle.world/">Virtuous Circle Sa</a> - <router-link class="link" to="/privacy-policy">Privacy Policy</router-link> - <router-link class="link" to="/terms-of-use">Terms of use</router-link>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer {
  color: $TEXT_W;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  p{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding-top: 15px;
    font-size: .9rem;
    a{
      padding: 0 8px;
    }
  }
}
@media screen and (max-width: 768px) {
  footer {
    height: auto;
    padding: 15px 5px;
    p{
      font-size: .8rem;
      flex-wrap: wrap;
      flex-direction: column;
      a{
        padding: 8px 0px;
      }
    }
  }
}
</style>
