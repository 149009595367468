import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      seoTitle: 'The Human-centric revolution',
      seoDescription: 'A revolutionary platform enabling people to overcome digital chaos and get the most out of the digital content in their lives.'
    }
  },
  {
    path: '/revolutionary-tool',
    name: 'revolutionary',
    component: () => import(/* webpackChunkName: "revolutionary" */ '../views/RevolutionaryTool.vue'),
    meta: {
      seoTitle: 'Connect yourself to the content you love',
      seoDescription: 'Human-Centric Technology that empowers your awareness and proactive role, while you organize, enjoy and share your digital content.'
    }
  },
  {
    path: '/maps-gallery',
    name: 'maps-gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/gallery/GalleryView.vue'),
    meta: {
      seoTitle: 'Get inspired by the Epicentric® Maps Gallery',
      seoDescription: 'Check out our Map Templates to see how many things you can do with your Epicentric®!'
    }
  },
  {
    path: '/maps-gallery/:idGallery/:idMap',
    name: 'gallery-detail',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/gallery/GalleryDetailView.vue'),
    meta: {
      seoTitle: 'Maps Gallery',
      seoDescription: 'Check out our Map Templates to see how many things you can do with your Epicentric®!'
    }
  },
  {
    // https://www.epicentric.world/epicentric_and_music.html
    path: '/epicentric-and-music',
    name: 'music',
    component: () => import(/* webpackChunkName: "music" */ '../views/EpicentricAndMusic.vue'),
    meta: {
      seoTitle: 'Set up your personal streaming platform.',
      seoDescription: 'Music Maps, Playlist engine, Multi-level customization. Epicentric® is by far the most powerful tool to organize, enjoy and share Music.'
    }
  },
  {
    // https://www.epicentric.world/epicentric_and_audio.html
    path: '/epicentric-and-audio',
    name: 'audio',
    component: () => import(/* webpackChunkName: "audio" */ '../views/EpicentricAndAudio.vue'),
    meta: {
      seoTitle: 'Get the most out of your Audio Collection',
      seoDescription: 'Audio Maps, Playlist engine and more. Epicentric® is by far the most powerful tool to organize, enjoy and share your Audio Collection'
    }
  },
  {
    // https://www.epicentric.world/epicentric_and_visual.html
    path: '/epicentric-and-visual',
    name: 'visual',
    component: () => import(/* webpackChunkName: "visual" */ '../views/EpicentricAndVisual.vue'),
    meta: {
      seoTitle: 'Make the most out of your Visual collection',
      seoDescription: 'Design your thematic, conceptual or chronological Maps. Enrich them with music, audio comments and notes and set up your Multimedia Journeys.'
    }
  },
  {
    // https://www.epicentric.world/epicentric_and_you.html
    path: '/epicentric-and-you',
    name: 'you',
    component: () => import(/* webpackChunkName: "you" */ '../views/EpicentricAndYou.vue'),
    meta: {
      seoTitle: 'It\'s not about digital content, it\'s about YOU.',
      seoDescription: 'Use Epicentric® to consciously choose and integrate any and all material of value from our digital word and chart your path of self-discovery and creation'
    }
  },
  {
    // https://www.epicentric.world/epicentric_lovers.html
    path: '/epicentric-lovers',
    name: 'lovers',
    component: () => import(/* webpackChunkName: "lovers" */ '../views/EpicentricLovers.vue'),
    meta: {
      seoTitle: 'Join the Epicentric® Revolution!',
      seoDescription: 'Active, free, unique, in touch with the Self: another way of being in a digital world.'
    }
  },
  {
    // https://www.epicentric.world/epicentric-vision.html
    path: '/epicentric-vision',
    name: 'epicentric-vision',
    component: () => import(/* webpackChunkName: "vision" */ '../views/EpicentricVision.vue'),
    meta: {
      seoTitle: 'Be the captain of your own digital destiny',
      seoDescription: 'Discover the ultimate vision of Epicentric®: to create an interface between one’s self and the digital world.'
    }
  },
  {
    // https://www.epicentric.world/epicentric-music-own.html
    path: '/epicentric-and-music/why-to-own-music',
    name: 'why-own-music',
    component: () => import(/* webpackChunkName: "music" */ '../views/music/WhyOwnMusic.vue'),
    meta: {
      seoTitle: 'Why to own music',
      seoDescription: '7 good reasons for a passionate choice. Choose to own your music!'
    }
  },
  {
    // https://www.epicentric.world/epicentric-music-lovers.html
    path: '/epicentric-and-music/music-lovers',
    name: 'music-lovers',
    component: () => import(/* webpackChunkName: "music" */ '../views/music/MusicLovers.vue'),
    meta: {
      seoTitle: 'Music Lovers',
      seoDescription: 'Truly passionate about music, not just listeners'
    }
  },
  {
    // https://www.epicentric.world/about.html
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      seoTitle: 'Epicentric® was created by Virtuous Circle',
      seoDescription: 'We create digital tools for Human Evolution, that allow people to further their growth and self-development.'
    }
  },
  {
    // https://www.epicentric.world/contact.html
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      seoTitle: 'Contact us',
      seoDescription: 'Contact us and we will answer you as soon as possible'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicyView.vue'),
    meta: {
      seoTitle: 'privacy policy',
      seoDescription: ''
    }
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUseView.vue'),
    meta: {
      seoTitle: 'terms of use',
      seoDescription: ''
    }
  },
  {
    path: '/digital-chaos',
    name: 'digital-chaos',
    component: () => import(/* webpackChunkName: "digital-chaos" */ '../views/DigitalChaos.vue'),
    meta: {
      seoTitle: 'The digital chaos',
      seoDescription: 'Epicentric helps us overcome one of the key challenges of this era: making the most of digital content in our lives.'
    }
  },
  {
    path: '/your-sacred-space',
    name: 'your-sacred-space',
    component: () => import(/* webpackChunkName: "your-sacred-space" */ '../views/YourSacredSpace.vue'),
    meta: {
      seoTitle: 'Your one and only sacred space',
      seoDescription: 'Your digital home and personal multimedia hub for all the content you want without limits.'
    }
  },
  {
    path: '/epicentric-vs',
    name: 'epicentric-vs',
    component: () => import(/* webpackChunkName: "epicentric-vs" */ '../views/EpicentricVs.vue'),
    meta: {
      seoTitle: 'Epicentric vs the world',
      seoDescription: 'Engineered to be different as a human-centric system.'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to) => {
  if (to.meta.seoTitle !== '') {
    if (to.name === 'gallery-detail') {
      const mapNameArray = to.params.idMap.split('-')
      let mapName = ''
      for (let i = 0; i < mapNameArray.length; i++) {
        const capitalizedGalleryName = mapNameArray[i].charAt(0).toUpperCase() + mapNameArray[i].slice(1)
        mapName += capitalizedGalleryName + ' '
      }

      const capitalizedGalleryId = to.params.idGallery.charAt(0).toUpperCase() + to.params.idGallery.slice(1)
      document.title = 'Epicentric® - ' + to.meta.seoTitle + ' | ' + capitalizedGalleryId + ' - ' + mapName
    } else {
      document.title = 'Epicentric® - ' + to.meta.seoTitle
    }
  }
  if (to.meta.seoDescription !== '') {
    document.getElementsByTagName('meta')['description'].content = to.meta.seoDescription
  }
})


export default router
